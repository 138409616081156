import login from "./auth/login/en";
import forgotPassword from "./auth/forgot-password/en";
import resetPassword from "./auth/reset-password/en";

import teamMembers from "./team-members/en";
import companies from "./companies/en";

import billing from "./billing/en";

const locales = {
  auth: {
    login,
    forgotPassword,
    resetPassword,
  },
  teamMembers,
  companies,
  billing,
};

export default locales;
