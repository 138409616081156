interface RoleType {
  translationKey: string;
  apiKey: string;
}

export const roles: RoleType[] = [
  { translationKey: "sales", apiKey: "sales" },
  { translationKey: "superAdmin", apiKey: "superAdmin" },
  { translationKey: "salesManager", apiKey: "salesManager" },
  { translationKey: "technicalSupport", apiKey: "technicalSupport" },
  {
    translationKey: "technicalSupportManager",
    apiKey: "technicalSupportManager",
  },
  { translationKey: "accountManager", apiKey: "accountManager" },
  {
    translationKey: "accountManagementManager",
    apiKey: "accountManagementManager",
  },
  { translationKey: "reportingRole", apiKey: "reportingRole" },
];

export const companyRoles: RoleType[] = [
  { translationKey: "superAdmin", apiKey: "superAdmin" },
  { translationKey: "IT", apiKey: "IT" },
  { translationKey: "marketing", apiKey: "marketing" },
  { translationKey: "operator", apiKey: "operator" },
  {
    translationKey: "analytics",
    apiKey: "analytics",
  },
];
export enum WinfiEmployeeRoles {
  superAdmin = "superAdmin",
  sales = "sales",
  salesManager = "salesManager",
  technicalSupport = "technicalSupport",
  technicalSupportManager = "technicalSupportManager",
  accountManager = "accountManager",
  accountManagementManager = "accountManagementManager",
  reportingRole = "reportingRole",
}

export enum CompanyEmployeesRoles {
  superAdmin = "superAdmin",
  IT = "IT",
  marketing = "marketing",
  operator = "operator",
  analytics = "analytics",
}

export const permissions = {
  lines: [
    WinfiEmployeeRoles.superAdmin,
    WinfiEmployeeRoles.technicalSupport,
    WinfiEmployeeRoles.technicalSupportManager,
  ],
  companyBasicInfo: [
    WinfiEmployeeRoles.superAdmin,
    WinfiEmployeeRoles.sales,
    WinfiEmployeeRoles.salesManager,
  ],
  branchesAndAssets: [
    WinfiEmployeeRoles.superAdmin,
    WinfiEmployeeRoles.sales,
    WinfiEmployeeRoles.salesManager,
    WinfiEmployeeRoles.technicalSupport,
    WinfiEmployeeRoles.technicalSupportManager,
  ],
  subscriptionPlan: [
    WinfiEmployeeRoles.superAdmin,
    WinfiEmployeeRoles.sales,
    WinfiEmployeeRoles.salesManager,
  ],
  viewTeamMembersOfTheCompany: [
    WinfiEmployeeRoles.superAdmin,
    WinfiEmployeeRoles.sales,
    WinfiEmployeeRoles.salesManager,
    WinfiEmployeeRoles.accountManager,
    WinfiEmployeeRoles.accountManagementManager,
    WinfiEmployeeRoles.technicalSupport,
    WinfiEmployeeRoles.technicalSupportManager,
  ],
  addTeamMemberToTheCompany: [
    WinfiEmployeeRoles.superAdmin,
    WinfiEmployeeRoles.accountManager,
    WinfiEmployeeRoles.accountManagementManager,
  ],
  billing: [
    WinfiEmployeeRoles.superAdmin,
    WinfiEmployeeRoles.accountManager,
    WinfiEmployeeRoles.accountManagementManager,
  ],
  activateCompany: [
    WinfiEmployeeRoles.superAdmin,
    WinfiEmployeeRoles.accountManager,
    WinfiEmployeeRoles.accountManagementManager,
  ],
  deactivateCompany: [
    WinfiEmployeeRoles.superAdmin,
    WinfiEmployeeRoles.accountManager,
    WinfiEmployeeRoles.accountManagementManager,
  ],
  blockCompany: [
    WinfiEmployeeRoles.superAdmin,
    WinfiEmployeeRoles.accountManagementManager,
    WinfiEmployeeRoles.accountManager,
  ],
  deleteCompany: [
    WinfiEmployeeRoles.superAdmin,
    WinfiEmployeeRoles.accountManagementManager,
  ],
  livePreviewAccess: [
    WinfiEmployeeRoles.superAdmin,
    WinfiEmployeeRoles.technicalSupport,
    WinfiEmployeeRoles.technicalSupportManager,
    WinfiEmployeeRoles.accountManager,
    WinfiEmployeeRoles.accountManagementManager,
  ],
  staffModule: [WinfiEmployeeRoles.superAdmin],
  doorAccess: [
    WinfiEmployeeRoles.superAdmin,
    WinfiEmployeeRoles.technicalSupportManager,
  ],
};
