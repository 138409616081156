import { coreAxiosInstance } from "../../api/axios";
import { AxiosRequestConfig } from "axios";

export const getter = async (url: any, configs?: AxiosRequestConfig) => {
  try {
    const response = await coreAxiosInstance.get(url, configs);
    return response.data;
  } catch (error: any) {
    if (error.response?.data) {
      throw error.response?.data;
    } else {
      throw new Error("something went wrong");
    }
  }
};
